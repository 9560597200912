/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useTheme, styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation } from 'react-router';
import useRegister from 'src/services/useRegister';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import separateNumberFormat from 'src/utils/separateNumberFormat';
import {
  BillableMetric,
  PlanItem,
  PlanSimulationResponse,
  PlanSimulationV2Params
} from 'src/models/register';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_BILLABLE_METRICS,
  GET_PLAN_SIMULATION_V2
} from 'src/services/graphql/register/query';
import { useAlert } from 'src/hooks/useAlert';
import {
  reducerUpdateSimulationPlanItems,
  reducerUpdateSimulationPromoCode
} from 'src/features/register';

interface Props {
  periodes: Array<any>;
  setPeriodes: any;
}

const LiteTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `
);

const ButtonPeriod = styled(Button)(
  ({ theme }) => `
       padding: ${theme.spacing(1)};
       font-size: 12px;
       background: ${theme.colors.gradients.blue1};
       &:hover {
        background: ${theme.colors.gradients.blue1};
       };
    `
);

const ButtonIcon = styled(Fab)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    color: ${theme.palette.common.white};
    &:hover {
        background: ${theme.colors.gradients.blue2};
    }
      `
);

interface BillableMetricsWithQuota extends BillableMetric {
  quota: number;
}

interface PlanDetail {
  name: string;
  metrics: Array<{
    code: string;
    quota: number;
  }>;
}

const planDetails: PlanDetail[] = [
  {
    name: 'starter',
    metrics: [
      { code: 'SOCIAL_PROFILE', quota: 2 },
      { code: 'KEYWORD_HASHTAG', quota: 2 },
      { code: 'ENTITY', quota: 0 },
      { code: 'CLIPPING', quota: 2 }
    ]
  },
  {
    name: 'lite',
    metrics: [
      { code: 'SOCIAL_PROFILE', quota: 4 },
      { code: 'KEYWORD_HASHTAG', quota: 4 },
      { code: 'ENTITY', quota: 0 },
      { code: 'CLIPPING', quota: 3 }
    ]
  },
  {
    name: 'advanced',
    metrics: [
      { code: 'SOCIAL_PROFILE', quota: 10 },
      { code: 'KEYWORD_HASHTAG', quota: 10 },
      { code: 'ENTITY', quota: 0 },
      { code: 'CLIPPING', quota: 8 }
    ]
  }
];

const PricingCalculator: React.FC<Props> = ({ periodes, setPeriodes }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const planParam = params.get('plan') ?? 'starter';
  const planCurrency = params.get('currency') ?? 'idr';
  const { handleClickAlert } = useAlert();
  const [billableMetricsWithQuota, setbillableMetricsWithQuota] = useState<
    BillableMetricsWithQuota[] | null
  >(null);
  const [promoCode, setPromoCode] = useState<string>('');

  const {
    data: billableMetrics,
    loading: billableMetricsLoading,
    error: billableMetricsError
  } = useQuery<{ billing_getBillableMetrics: BillableMetric[] }>(
    GET_BILLABLE_METRICS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onCompleted({ billing_getBillableMetrics }) {
        // get initial metric quota
        const dataWithQuota: BillableMetricsWithQuota[] =
          billing_getBillableMetrics.map((metric) => {
            const plan = planDetails.find((plan) => plan.name === planParam);
            const planMetric = plan.metrics.find(
              (planMetric) => planMetric.code === metric.code
            );
            return {
              ...metric,
              quota: planMetric.quota
            };
          });
        getPlanSimulationV2({
          variables: {
            countryId: initCountryId,
            period: selectedPeriod.value,
            promoCode: '',
            planItems: dataWithQuota.map((metric) => {
              return { billableMetricId: metric.id, quota: metric.quota };
            })
          }
        });
        setbillableMetricsWithQuota(dataWithQuota);
      }
    }
  );

  const { getUserPackagePlan, getUserPlanSimulation } = useRegister();

  const packagePlan = useAppSelector((state) => state.register.userPackagePlan);
  const planSimulation = useAppSelector(
    (state) => state.register.planSimulation
  );
  const selectedCountryId = useAppSelector(
    (state) => state.register.selectedCountryId
  );
  const initCountryId =
    planCurrency === 'idr'
      ? '9cd88e16-510e-49f0-9ce8-f4d27f0b85bb'
      : '162021c9-becc-11eb-a7ce-1e87ea3f1b00';

  const selectedPeriod = useMemo(
    () => periodes.find((period) => period.isActive),
    [periodes]
  );

  const [getPlanSimulationV2, { data: planSimulationData, loading, error }] =
    useLazyQuery<
      {
        billing_getPlanSimulationV2: PlanSimulationResponse;
      },
      PlanSimulationV2Params
    >(GET_PLAN_SIMULATION_V2, {
      onCompleted: (data) => {
        dispatch(
          reducerUpdateSimulationPromoCode(
            data.billing_getPlanSimulationV2.promoCode
          )
        );
        dispatch(
          reducerUpdateSimulationPlanItems(
            data.billing_getPlanSimulationV2.planItems
          )
        );
      },
      onError: () => {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: 'Error fetching price, please try again.',
          severity: 'error'
        });
      }
    });

  const handleChangeQuota = (code: string, type: 'increment' | 'decrement') => {
    const updatedState = billableMetricsWithQuota.map((metric) => {
      if (metric.code !== code) return metric;
      return {
        ...metric,
        quota: type === 'increment' ? metric.quota + 1 : metric.quota - 1
      };
    });
    const planItems: PlanItem[] = updatedState.map((metric) => {
      return { billableMetricId: metric.id, quota: metric.quota };
    });
    getPlanSimulationV2({
      variables: {
        countryId: initCountryId,
        period: selectedPeriod.value,
        promoCode: '',
        planItems: planItems
      }
    });
    setbillableMetricsWithQuota(updatedState);
  };

  const handleChangePeriod = (id: string) => {
    setPeriodes((prevPeriodes) =>
      prevPeriodes.map((period) => {
        if (period.id === id) return { ...period, isActive: true };
        return { ...period, isActive: false };
      })
    );
  };

  const getCurrentPlanName = (
    state: BillableMetricsWithQuota[] | null,
    plans: PlanDetail[]
  ): string => {
    for (let plan of plans) {
      // Compare all metrics in the plan with the state
      const isMatchingPlan = plan.metrics.every((metric) => {
        // Find the corresponding item in the state with the same `code`
        const stateItem = state.find((item) => item.code === metric.code);
        // If the item exists in the state, check if the quota matches
        return stateItem && stateItem.quota === metric.quota;
      });

      // If all metrics match, break the loop & return the plan's name
      if (isMatchingPlan) {
        const planName = plan.name.charAt(0).toUpperCase() + plan.name.slice(1);
        return planName;
      }
    }
    // Return 'custom' if no matching plan is found
    return 'Custom';
  };

  const handleUsePromo = (promoCode: string) => {
    getPlanSimulationV2({
      variables: {
        planItems: billableMetricsWithQuota.map((metric) => {
          return { billableMetricId: metric.id, quota: metric.quota };
        }),
        countryId: initCountryId,
        period: selectedPeriod.value,
        promoCode: promoCode
      }
    }).then((res) => {
      const data = res.data.billing_getPlanSimulationV2;
      // update metrics quota if promo type is `package`
      if (data.promoType === 'package') {
        const updatedMetrics = billableMetricsWithQuota.map((metric) => {
          const matchedMetric = data.planItems.find(
            (plan) => plan.billableMetricId === metric.id
          );
          return { ...metric, quota: matchedMetric.quota };
        });
        setbillableMetricsWithQuota(updatedMetrics);
      }
    });
  };

  useEffect(() => {
    if (!billableMetricsWithQuota) return;
    getPlanSimulationV2({
      variables: {
        planItems: billableMetricsWithQuota.map((metric) => {
          return { billableMetricId: metric.id, quota: metric.quota };
        }),
        countryId: initCountryId,
        period: selectedPeriod.value,
        promoCode: ''
      }
    });
  }, [initCountryId, selectedPeriod]);

  return (
    <Paper
      sx={{
        width: theme.spacing(49),
        transform: 'translateY(60px)',
        padding: 4,
        [theme.breakpoints.down('sm')]: {
          pt: 0,
          mb: '56px'
        },
        borderRadius: '12px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <LiteTypography variant="h1">
          {billableMetricsWithQuota
            ? getCurrentPlanName(billableMetricsWithQuota, planDetails)
            : planParam}
        </LiteTypography>
      </Box>
      <Box
        sx={{
          height: theme.spacing(5),
          width: '100%',
          background: '#F5F5F5',
          borderRadius: '100px',
          mt: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid container>
          {periodes.map(({ id, label, isActive }) => {
            if (isActive)
              return (
                <Grid item sm={4} key={id}>
                  <ButtonPeriod variant="contained" fullWidth>
                    {label}
                  </ButtonPeriod>
                </Grid>
              );
            return (
              <Grid item sm={4} key={id}>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => handleChangePeriod(id)}
                >
                  {label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {billableMetricsLoading ? (
        <Box
          height="300px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={50} />
        </Box>
      ) : null}
      {billableMetricsWithQuota ? (
        <>
          {billableMetricsWithQuota.map((metric, index, array) => (
            <Box key={metric.id}>
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {metric.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: theme.spacing(16)
                  }}
                >
                  <ButtonIcon
                    aria-label="minus"
                    size="small"
                    onClick={() => handleChangeQuota(metric.code, 'decrement')}
                    disabled={metric.quota < 1}
                  >
                    <RemoveIcon
                      sx={{
                        background: theme.palette.common.white,
                        borderRadius: '50%',
                        color: '#47A3E6',
                        fontSize: theme.spacing(4)
                      }}
                    />
                  </ButtonIcon>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {metric.quota}
                  </Typography>
                  <ButtonIcon
                    aria-label="plus"
                    size="small"
                    onClick={() => handleChangeQuota(metric.code, 'increment')}
                  >
                    <AddIcon
                      sx={{
                        background: theme.palette.common.white,
                        borderRadius: '50%',
                        color: '#47A3E6',
                        fontSize: theme.spacing(4)
                      }}
                    />
                  </ButtonIcon>
                </Box>
              </Box>
              {index < array.length - 1 && (
                <Divider
                  sx={{
                    my: theme.spacing(2)
                  }}
                />
              )}
            </Box>
          ))}
        </>
      ) : null}
      <Divider
        sx={{
          mt: theme.spacing(4),
          position: 'absolute',
          left: 0,
          right: 0
        }}
      />
      <Stack
        direction="column"
        gap={2}
        sx={{
          mt: theme.spacing(7)
        }}
      >
        <FormControl fullWidth size="medium">
          <FormLabel>Promo Code</FormLabel>
          <Stack direction="row" gap={0} width="100%">
            <OutlinedInput
              name="promoCode"
              onChange={(e) => setPromoCode(e.target.value)}
              // error={errors.companyName && touched.companyName}
              value={promoCode}
              sx={{
                flexGrow: 1,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <Button
              variant="contained"
              disabled={billableMetricsLoading}
              sx={{
                borderRadius: 1,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              onClick={() => handleUsePromo(promoCode)}
            >
              Use
            </Button>
          </Stack>
        </FormControl>
        {planSimulationData?.billing_getPlanSimulationV2?.discount ? (
          <Stack direction="row" gap={1}>
            <Typography
              variant="body1"
              fontWeight={600}
              color="#9D9D9C"
              sx={{ textDecoration: 'line-through' }}
            >
              {`Rp${separateNumberFormat(
                planSimulationData?.billing_getPlanSimulationV2?.subTotal ?? 0
              )}`}
              {'/'}
              {selectedPeriod.period}
            </Typography>
            <span
              style={{
                color: '#47A3E6',
                textDecorationLine: 'none',
                fontWeight: 600
              }}
            >
              (
              {separateNumberFormat(
                planSimulationData?.billing_getPlanSimulationV2?.discount ?? 0
              )}{' '}
              off)
            </span>
          </Stack>
        ) : null}
        <Box>
          <Typography variant="body2">Grand Total</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography variant="h2">{`Rp${separateNumberFormat(
              planSimulationData?.billing_getPlanSimulationV2?.total ?? 0
            )}`}</Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 'bold',
                ml: theme.spacing(0.5),
                transform: 'translateY(4px)'
              }}
            >{` / ${selectedPeriod.period}`}</Typography>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};

export default PricingCalculator;
