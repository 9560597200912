/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import useUsers from 'src/services/useUsers';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect } from 'react';
import { IProject } from 'src/models/users';
import {
  ONBOARDING_ADD_PROFILE,
  SOCIAL_MEDIA_OVERVIEW,
  WARNING_OBJECT_OVERLAP
} from 'src/route';
import { reducerSetProject } from 'src/features/users';
import useObjectManagement from 'src/services/useObjectManagement';
import useOnlineMedia from 'src/services/useOnlineMedia';
import useCampagin from 'src/services/useCampaign';
import useLabels from 'src/services/useLabels';
import useCustomDashboard from 'src/services/useCustomDashboard';
import useOnboarding from 'src/services/useOnboarding';
import { reducerUpdateLanguages } from 'src/features/onlineMediaStream';
import { reducerUpdateLanguages as reducerUpdateLanguagesOfm } from 'src/features/offlineMediaStream';
import { useQuery } from '@apollo/client';
import { ONLINE_MEDIA_GET_LANGUAGES } from 'src/services/graphql/onlineMedia/query';
import ScreenLoader from 'src/components/ScreenLoader';
import useBillingSystem from 'src/services/useBillingSystem';
import { GET_LANGUAGES_V2 } from 'src/services/graphql/stream/query';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Loading() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.users.userProfile);
  const projectActive = useAppSelector((state) => state.users.project);
  const clippingList = useAppSelector(
    (state) => state.storeOnlineMediaStream.clippingList.data
  );
  const { getUserProfile, getListMember, getPendingInvitation, getListRole } =
    useUsers();
  const { getObjectList, getGroupObject } = useObjectManagement();
  const { getClippingsV5 } = useOnlineMedia();
  const { getListCampaign } = useCampagin();
  const { getLabelSocialMedia, getLabelArticle, getLabelPeople } = useLabels();
  const { getCustomDashboardList } = useCustomDashboard();
  const { getOnBoardingCountry } = useOnboarding();
  const { getCurrentPlan, useGetUserSubscription } = useBillingSystem();

  useEffect(() => {
    getUserProfile();
  }, []);

  useQuery(ONLINE_MEDIA_GET_LANGUAGES, {
    onCompleted: ({ onlineMedia_getLanguages }) => {
      dispatch(reducerUpdateLanguages(onlineMedia_getLanguages));
      dispatch(reducerUpdateLanguagesOfm(onlineMedia_getLanguages));
    }
  });

  useQuery(GET_LANGUAGES_V2, {
    fetchPolicy: 'cache-first'
  });

  useGetUserSubscription({
    variables: { projectId: projectActive?.id },
    skip: !!projectActive
  });

  const setNavigate = (project: IProject) => {
    const { onBoarding, isObjectOverlap } = project;
    if (!onBoarding.isOnBoarding) {
      navigate(ONBOARDING_ADD_PROFILE);
      return;
    }
    if (isObjectOverlap) {
      navigate(WARNING_OBJECT_OVERLAP);
    }
    getObjectList(project.id);
    getGroupObject({ projectId: project.id, templateName: 'content' });
    getClippingsV5({
      projectId: project.id,
      page: clippingList.page,
      limit: clippingList.limit,
      name: ''
    });
    getListCampaign(project.id);
    getLabelSocialMedia(project.id);
    getLabelArticle(project.id);
    // getAutoLabelSocialMedia(project.id);
    getLabelPeople(project.id);
    getListMember({ projectId: project.id, limit: 100, page: 1 });
    getPendingInvitation({ projectId: project.id, limit: 20, page: 1 });
    getCustomDashboardList(project.id);
    getOnBoardingCountry(project.id);
    getListRole();
    getCurrentPlan(project.id);
  };

  const setProjectActive = (projectList: Array<IProject>) => {
    let project: IProject = undefined;
    const projectAvailable = window.localStorage.getItem('project');
    if (projectAvailable) {
      project = projectList.find((project) => project.id === projectAvailable);
    } else {
      project = projectList[0];
    }
    dispatch(reducerSetProject(project));
    window.localStorage.setItem('project', project.id);
    setNavigate(project);
  };

  useEffect(() => {
    if (!userProfile.data) return;
    const { projectList } = userProfile.data;
    setProjectActive(projectList);
  }, [userProfile.data]);

  useEffect(() => {
    if (!projectActive) return;
    navigate(SOCIAL_MEDIA_OVERVIEW);
  }, [projectActive]);

  return (
    <>
      {/* <Helmet>
        <title>NoLimit Dashboard V5</title>
      </Helmet> */}
      <MainContent>
        <ScreenLoader />
      </MainContent>
    </>
  );
}

export default Loading;
